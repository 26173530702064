import React from "react"

const Footer = () => {
  return (
    <footer>
      <h4>ADAM HELDRING</h4>
      <p>hello@adamheldring.com</p>
      <p>+46 708-841828</p>
    </footer>
  )
}

export default Footer
